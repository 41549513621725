import { scrollElementTo } from 'widgets/toolbox/scroll';
import { getViewType } from 'widgets/toolbox/viewtype';
import cssLoadChecker from 'widgets/toolbox/cssLoadChecker';
import { timeout } from 'widgets/toolbox/util';

const DEFAULT_DIRECTION = 'horizontal';
const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Carousel block implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof Carousel} Carousel class
 */
export default function (Widget) {
    /**
     * @class Carousel
     * @augments Widget
     * @classdesc Carousel widget
     * <br>Uses as a basis slider from here (ScrollCarousel.js):
     * <br>https://github.com/dimanech/aria-components/tree/master/cartridge1/js/components/carousels/carousel
     * @property {string} data-widget - Widget name `carousel`
     * @property {string} data-elem-prev-button - Previous button element
     * @property {string} data-elem-next-button - Next button element
     * @property {string} data-elem-carousel-track - Carousel inner element
     * @property {string} data-direction - Carousel direction - an object, contains direction per viewport
     * @example
     * // use this code to display widget
     * <div
     *     data-widget="carousel"
     *     data-elem-prev-button="elemPrevButton"
     *     data-elem-next-button="elemNextButton"
     *     data-elem-carousel-track="elemCarouselTrack"
     *     data-direction='{
     *         "small": "horizontal",
     *         "medium": "horizontal",
     *         "large": "vertical",
     *         "extraLarge": "vertical"
     *     }'
     * >
     *     <button
     *         class="carousel__ctrl _prev"
     *         data-elem-prev-button
     *         tabindex="-1"
     *         aria-hidden="true"
     *         data-ref="elemPrevButton"
     *         data-event-click="scrollToPrevPage"
     *     >Prev</button>
     *     <div
     *         class="carousel__track"
     *         data-elem-carousel-track
     *         data-ref="elemCarouselTrack"
     *         data-event-scroll="onScroll"
     *         data-event-touchstart="onScroll"
     *         data-event-mousedown.prevent="onMouseDown"
     *         data-event-mouseup="onMouseUp"
     *     >
     *         <isloop items="${slotcontent.content}" var="contentAsset">
     *             <div class="box _single" tabindex="0">
     *                 <isprint value="${contentAsset.custom.body}" encoding="off" />
     *             </div>
     *         </isloop>
     *     </div>
     *     <button
     *         class="carousel__ctrl _next"
     *         data-elem-next-button
     *         tabindex="-1"
     *         aria-hidden="true"
     *         data-ref="elemNextButton"
     *         data-event-click="scrollToNextPage"
     *     >Next</button>
     *     <div class="pagination" data-ref="pagination"></div>
     *     <script type="template/mustache" data-ref="template">
     *         <div class="pagination" data-ref="pagination">
     *             {{${'#'}pagination}}
     *             <button
     *                 class="page"
     *                 data-page="{{page}}"
     *                 tabindex="-1"
     *                 data-event-click.prevent="handlePaginationClick"
     *             >
     *             </button>
     *             {{/pagination}}
     *         </div>
     *     </script>
     * </div>
     */
    class Carousel extends Widget {
        prefs() {
            return {
                elemPrevButton: 'elemPrevButton',
                elemNextButton: 'elemNextButton',
                elemCarouselTrack: 'elemCarouselTrack',
                pagerCurrentClass: 'm-current',
                direction: {
                    small: 'horizontal',
                    medium: 'horizontal',
                    large: 'horizontal',
                    extraLarge: 'horizontal'
                },
                zoomClass: 'm-zoomed-in',
                ...super.prefs()
            };
        }

        update() {
            this.updateCarouselMetric();
            this.updateCarouselState();
            this.initPagination();
            this.setActivePagination();
            this.ref('self').addClass('m-inited');
            return this;
        }

        defineCarouselDirection() {
            this.carouselDirection = this.prefs().direction[getViewType()] || DEFAULT_DIRECTION;
        }

        init() {
            super.init();
            // Async loading to not block other widget init
            timeout(() => {
                cssLoadChecker.get().then(() => this.initCarousel());
            }, 0);
        }

        initCarousel() {
            this.defineCarouselDirection();
            this.eventBus().on('viewtype.change', 'onViewtypeChange');
            this.ev('focusin', this.handleScrollToFocusedItem, this.ref('elemCarouselTrack').get());

            this.currentPage = 0;
            this.scrollingTimeoutValue = 60;
            this.grabbingTimeoutValue = 60;
            this.deltaPrevPageSensitivity = -10;
            this.deltaNextPageSensitivity = 10;
            // fixes issue with rounding slides width for responsive markup
            this.roundingDelta = 1;

            this.onScroll();
            this.updateCarouselState();
            this.initPagination();
            this.setActivePagination();

            this.ref('self').addClass('m-inited');
        }

        /**
         * @description viewtype change event handler. Recalculates carousel dimension and redefines carousel direction.
         */
        onViewtypeChange() {
            this.carouselDirection = undefined;
            this.dimension = undefined;
            this.defineCarouselDirection();
            this.getCarouselDimension();
        }

        /**
         * @description Scrolls active element into carousel viewport.
         * This might be useful when using dependant carousels, like on PDP images/thumbnails
         *
         * @returns {Carousel} carousel instance
         */
        scrollIntoView() {
            const elemCarouselTrack = this.ref('elemCarouselTrack').get();
            if (elemCarouselTrack) {
                const currentElement = [].find.call(elemCarouselTrack.children, (/** @type {HTMLElement} */element) => {
                    return element.classList.contains(this.prefs().pagerCurrentClass);
                });

                if (currentElement) {
                    if (this.carouselDirection === DEFAULT_DIRECTION) {
                        if ((currentElement.offsetLeft + currentElement.clientWidth)
                            > (elemCarouselTrack.scrollLeft + elemCarouselTrack.clientWidth)) {
                            this.scrollToPoint(0, currentElement.offsetLeft);
                        } else if (currentElement.offsetLeft < elemCarouselTrack.scrollLeft) {
                            this.scrollToPoint(0, currentElement.offsetLeft);
                        }
                    } else {
                        // eslint-disable-next-line no-lonely-if
                        if ((currentElement.offsetTop + currentElement.clientHeight)
                            > (elemCarouselTrack.scrollTop + elemCarouselTrack.clientHeight)) {
                            this.scrollToPoint(currentElement.offsetTop, 0);
                        } else if (currentElement.offsetTop < elemCarouselTrack.scrollTop) {
                            this.scrollToPoint(currentElement.offsetTop, 0);
                        }
                    }
                }
            }

            return this;
        }

        initPagination() {
            if (this.hasNoScroll()) {
                return;
            }

            this.has('pagination', paginationRefEl => {
                const pagination = paginationRefEl.get();
                if (pagination) {
                    // If empty pagination - we need to render it. Otherwise - create.
                    if (pagination.innerHTML === '') {
                        this.createPaginationElements();
                    } else {
                        this.pagination = Promise.resolve(pagination);
                    }
                }
            });
        }

        /**
         * @param {refElement} el source of event
         */
        handlePaginationClick(el) {
            const pageIndex = el.data('page');
            if (pageIndex !== null) {
                this.scrollToPage(parseInt(pageIndex + '', 10));
            }
        }

        createPaginationElements() {
            const elemCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elemCarouselTrack) {
                return;
            }
            // We need to use round, not ceil, since it called on scroll,
            // in case of last it would generate falls positive
            const numberOfPages = Math.round(elemCarouselTrack.scrollWidth / elemCarouselTrack.clientWidth);
            const pagination = new Array(numberOfPages).fill(0).map((_el, i) => ({ page: i }));

            this.pagination = new Promise((resolve) => {
                this.render(undefined, { pagination }, this.ref('pagination')).then(() => {
                    resolve(this.ref('pagination').get());
                });
            });
        }

        setActivePagination() {
            if (!this.pagination) {
                return;
            }

            this.pagination.then((pagination) => {
                if (!pagination) {
                    return;
                }

                const currentPageIndex = this.getCurrentPageIndex();
                const currentPageNode = pagination.children[currentPageIndex];

                if (!currentPageNode) {
                    this.initPagination();
                }

                pagination.children[this.currentPage || 0].classList.remove(
                    this.prefs().pagerCurrentClass
                );

                currentPageNode.classList.add(this.prefs().pagerCurrentClass);

                this.currentPage = currentPageIndex;
            });
        }

        getCurrentPageIndex() {
            const carouselTrackElem = this.ref('elemCarouselTrack').get();
            if (carouselTrackElem) {
                const currentPosition = this.carouselDirection === 'horizontal'
                    ? carouselTrackElem.scrollLeft : carouselTrackElem.scrollTop;
                const pageDimension = this.carouselDirection === 'horizontal'
                    ? carouselTrackElem.clientWidth : carouselTrackElem.clientHeight;
                return Math.round((currentPosition || 0) / (pageDimension || 1));
            } else {
                return 0;
            }
        }

        getImages() {
            const imagesContainer = this.ref('elemCarouselTrack').get();

            return imagesContainer ? imagesContainer.querySelectorAll('img') : [];
        }

        scrollToNextPage() {
            this.scrollToPage(this.getCurrentPageIndex() + 1);
        }

        scrollToPrevPage() {
            this.scrollToPage(this.getCurrentPageIndex() - 1);
        }

        onPageClick(el) {
            if (this.delta === 0) {
                this.emit('pageclicked', el.data('page'));
            }
        }

        scrollToPage(pageIndex) {
            const self = this.ref('self').get();
            if (this.carouselDirection === 'horizontal') {
                this.scrollToPoint(0, Math.round(((self && self.clientWidth) || 0) * pageIndex));
            } else {
                this.scrollToPoint(Math.round(((self && self.clientHeight) || 0) * pageIndex), 0);
            }
            return this;
        }

        /**
         * @description Adds some defined class on carousel inner elements
         * @param {number} pageIndex - element index
         *
         * @returns {Carousel} carousel instance
         */
        markCurrentPage(pageIndex) {
            const elemCarouselTrack = this.ref('elemCarouselTrack').get();
            [].slice.call((elemCarouselTrack && elemCarouselTrack.children)).forEach((/** @type {HTMLElement} */element) => {
                const dataPage = parseInt(((element && element.getAttribute('data-page')) || '0'), 10);
                element.classList.toggle(this.prefs().pagerCurrentClass, dataPage === pageIndex);
            });
            return this;
        }

        scrollToPoint(top, left, node) {
            const element = node || this.ref('elemCarouselTrack').get();
            if (element) {
                scrollElementTo(element, top, left);
            }
        }

        hasNoScroll() {
            return this.isScrollStart && this.isScrollEnd;
        }

        onScroll() {
            this.updateCarouselMetric();

            if (!this.isCallInNextFrameRequested) {
                window.requestAnimationFrame(this.updateDuringScroll.bind(this));
                this.isCallInNextFrameRequested = true;
            }
        }

        onScrollEnd() {
            this.emit('pagechanged', this.getCurrentPageIndex());
        }

        updateDuringScroll() {
            this.updateCarouselState();
            if (this.pagination) {
                this.setActivePagination();
            } else {
                this.initPagination();
            }

            if (this.scrollingTimeout) {
                clearTimeout(this.scrollingTimeout);
            }

            this.scrollingTimeout = setTimeout(() => {
                this.onScrollEnd();
            }, this.scrollingTimeoutValue);

            this.isCallInNextFrameRequested = false;
        }

        updateCarouselState() {
            const carousel = this.ref('self');
            const elemPrevButton = this.ref('elemPrevButton');
            const elemNextButton = this.ref('elemNextButton');

            if (this.hasNoScroll()) { // No scroll case
                carousel.addClass('m-no_scroll');
            } else {
                carousel.removeClass('m-no_scroll');
            }

            if (this.isScrollStart) {
                carousel.removeClass('m-prev_visible');
                elemPrevButton.attr('disabled', '');
            } else {
                carousel.addClass('m-prev_visible');
                elemPrevButton.attr('disabled', false);
            }

            if (this.isScrollEnd) {
                carousel.removeClass('m-next_visible');
                elemNextButton.attr('disabled', '');
            } else {
                carousel.addClass('m-next_visible');
                elemNextButton.attr('disabled', false);
            }
        }

        updateCarouselMetric() {
            const carouselTrackElem = this.ref('elemCarouselTrack').get();
            const carouselElem = this.ref('self').get();
            const roundingDelta = this.roundingDelta || 0;
            if (carouselTrackElem && carouselElem) {
                if (this.carouselDirection === 'horizontal') {
                    const totalScrollWidth = carouselTrackElem.scrollLeft + carouselElem.offsetWidth;
                    this.isScrollStart = carouselTrackElem.scrollLeft <= 0;
                    this.isScrollEnd = (totalScrollWidth + roundingDelta) >= carouselTrackElem.scrollWidth;
                } else {
                    const totalScrollHeight = carouselTrackElem.scrollTop + carouselElem.offsetHeight;
                    this.isScrollStart = carouselTrackElem.scrollTop <= 0;
                    this.isScrollEnd = (totalScrollHeight + roundingDelta) >= carouselTrackElem.scrollHeight;
                }
            }
        }

        /**
         * @description Method to get event coordinate depending on carousel direction
         *
         * @param {object} event event object
         * @returns {number} event coordinate
         */
        getCurrentCoordinate(event) {
            let currentCoordinate = 0;

            if (event instanceof MouseEvent) {
                currentCoordinate = this.carouselDirection === 'horizontal'
                    ? event.clientX
                    : event.clientY;
            } else if (event instanceof TouchEvent) {
                currentCoordinate = this.carouselDirection === 'horizontal'
                    ? event.touches[0].pageX
                    : event.touches[0].pageY;
            }

            return currentCoordinate;
        }

        /**
         * @description Method to get carousel dimension (width or height) depending on carousel direction
         *
         * @returns {number} width or height of carousel
         */
        getCarouselDimension() {
            if (!this.dimension) {
                this.dimension = 0;
                const self = this.ref('self').get();

                if (self) {
                    this.dimension = this.carouselDirection === 'horizontal'
                        ? self.clientWidth
                        : self.clientHeight;
                }
            }

            return this.dimension;
        }

        /**
         * @description Programmatically handle scroll to focused carousel item.
         * This is the fix for Chrome bug where it could not calculate item boundaries properly
         * and threat item like it into view. The bug is on PDP Gallery.
         * Applicable only for horizontal scroll.
         * @returns {void}
         */
        handleScrollToFocusedItem() {
            if (this.carouselDirection !== 'horizontal') { return; }

            const track = this.ref('elemCarouselTrack').get();
            const activeItem = document.activeElement;
            if (!activeItem || !(activeItem instanceof HTMLElement) || activeItem.parentElement !== track) {
                return;
            }

            const activeItemLeft = activeItem.getBoundingClientRect().left;
            const trackStart = track.getBoundingClientRect().left;
            const trackEnd = trackStart + track.clientWidth;

            if (activeItemLeft >= trackEnd || activeItemLeft <= trackStart) {
                track.scrollTo(activeItem.offsetLeft, 0);
            }
        }

        /**
         * @description Next/Prev Buttons Keydown Event handler
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;
            let scrollDirection;
            const button = event.target;

            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    if (!(button instanceof HTMLElement)) { break; }

                    if (event.target && 'thumbnailsArrow' in button.dataset) {
                        scrollDirection = button.dataset.thumbnailsArrow;
                    } else {
                        break;
                    }

                    if (scrollDirection === 'next') {
                        this.scrollToNextPage();
                    } else if (scrollDirection === 'prev') {
                        this.scrollToPrevPage();
                    }

                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        /**
         * @param {refElement} el source of event
         * @param {MouseEvent} event event instance in DOM
         */
        onMouseDown(el, event) {
            this.initialCoordinate = this.getCurrentCoordinate(event);
            this.delta = 0;
            this.getCarouselDimension();

            const elemCarouselTrack = this.ref('elemCarouselTrack');

            this.mouseMoveDisposable = this.ev('mousemove', this.onMouseMove, elemCarouselTrack.get());
            this.mouseLeaveDisposable = this.ev('mouseleave', this.onMouseUp, elemCarouselTrack.get());
            clearTimeout(this.grabbingRemoveTimeout);
        }

        onMouseUp() {
            const elemCarouselTrack = this.ref('elemCarouselTrack');
            const GRABBING_CLASS = 'm-grabbing';

            if (this.mouseMoveDisposable) {
                this.mouseMoveDisposable.forEach(disposable => disposable());
                delete this.mouseMoveDisposable;
            }
            if (this.mouseLeaveDisposable) {
                this.mouseLeaveDisposable.forEach(disposable => disposable());
                delete this.mouseLeaveDisposable;
            }

            // we should remove scroll-snap-type with delay, otherwise it cause bouncing
            this.grabbingRemoveTimeout = setTimeout(() => {
                elemCarouselTrack.removeClass(GRABBING_CLASS);
            }, this.grabbingTimeoutValue);

            if (!this.delta) {
                this.delta = 0;
            }

            if (!this.deltaPrevPageSensitivity) {
                this.deltaPrevPageSensitivity = 0;
            }

            if (!this.deltaNextPageSensitivity) {
                this.deltaNextPageSensitivity = 0;
            }

            if (this.delta <= this.deltaPrevPageSensitivity) {
                this.scrollToPrevPage();
            } else if (this.delta >= this.deltaNextPageSensitivity) {
                this.scrollToNextPage();
            } else {
                // remove immediate for this case
                elemCarouselTrack.removeClass(GRABBING_CLASS);
            }
        }

        /**
         * @description Handle Mouse/Touch move
         * @param {HTMLElement} _el HTMLElement
         * @param {Event} event DOM event
         */
        onMouseMove(_el, event) {
            const currentCoordinate = this.getCurrentCoordinate(event);

            if (this.initialCoordinate && this.initialCoordinate !== currentCoordinate) {
                // eslint-disable-next-line no-mixed-operators
                this.delta = (this.initialCoordinate - currentCoordinate) / this.getCarouselDimension() * 100;

                this.has('elemCarouselTrack', (carouselTrack) => {
                    carouselTrack.addClass('m-grabbing');
                    const elemCarouselTrack = carouselTrack.get();

                    if (elemCarouselTrack && this.delta) {
                        if (this.carouselDirection === 'horizontal') {
                            this.scrollToPoint(0, elemCarouselTrack.scrollLeft + this.delta);
                        } else {
                            this.scrollToPoint(elemCarouselTrack.scrollTop + this.delta, 0);
                        }
                    }
                });
            }
        }

        /**
         * @description Toggle Zoom state
         * @param {boolean} state flag for toggle zoom state
         */
        toggleZoomState(state) {
            this.ref('self').toggleClass(this.prefs().zoomClass, state);
        }
    }

    return Carousel;
}
